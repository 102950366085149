<!--
 * @Author: lzh
 * @Date: 2022-12-13 11:02:38
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-07 15:07:44
 * @Description: file content
-->
<template>
  <div
    class="text-ricepos-box px-text-ricepos-box"
    :style="`background-image: url(${configs.bgImg});
    padding-top: ${configs.paddingTop}px;
    padding-right: ${configs.paddingRight}px;
    padding-bottom: ${configs.paddingBottom}px;
    padding-left: ${configs.paddingLeft}px;
    `"
    @click="clickItem('bgImage',9)"
  >
    <div class="text-box">
      <div class="line-title">
        <div class="line"></div>
        <h1
          class="title"
          @click.stop="clickItem('title',10)"
          :class="`edit_${Xindex}_title`"
          v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
        ></h1>
      </div>
      <div
        class="desc"
        :style="`margin-top: ${configs.descMarginTop}px !important;`"
        v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
        @click.stop="clickItem('desc',11)"
        :class="`edit_${Xindex}_desc`"
      ></div>
      <div
        v-for="item,index in configs.items"
        :key="index"
        @click.stop="clickItem('items',12)"
        class="item"
        :style="
        index == 0 ? `margin-top: ${configs.itemsMarginTop}px;` : ''
        "
      >
        <h2
          class="item-title"
          v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
          :style="`margin-top: ${configs.itemMarginTop}px;`"
        ></h2>
        <div
          class="item-desc"
          v-html="$util.rex.getHtml(item[`${nowL}desc`] || item.desc)"
        ></div>
      </div>
    </div>
    <div
      class="image-box"
      :style="`padding-left:${configs.marginLeft}px;`"
      @click.stop="clickItem('image',13)"
      :class="`edit_${Xindex}_src`"
    >
      <el-image
        :src="configs.image"
        :alt="configs.alt"
      ></el-image>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
import selfImage from "./image.vue";
export default {
  name: "self-text-ricePOS",
  mixins: [langMixin],
  components: {
    selfImage
  },
  props: {
    Xindex: {
      default () {
        return '';
      },
    },
    configs: {
      default () {
        return {
          title: 'RicePOS',
          desc: "雲端餐飲管理系統隨時隨地助你輕鬆營運",
          src: require('../../assets/testImg/RicePOSIphone.png'),
          bgImg: require('../../assets/testImg/bgImgricePOS.png'),
          marginLeft: 75,
          // itemsLineHeight: 25,
          paddingTop: 74,
          paddingRight: 273,
          paddingBottom: 74,
          paddingLeft: 360,
          items: [
            {
              title: '落單埋單一機過',
              desc: '用個APP完成從落單到打折到埋單，大大節省人力成本，提高餐廳翻檀率',
            },
            {
              title: '[餐飲難題一機解決',
              desc: '做住堂食又可以接外賣，疫情時代兩種模式缺一不可',
            },
            {
              title: "數據分析一機睇曬",
              desc: "多種報表管理及分析，為你展示營運實況，助你更容易制定營運策略"
            },
            {
              title: "餐飲難題一機解決",
              desc: "適用於小型、中型及大型連鎖餐廳，無論鯀中西意日韓，總有一套解幫到你"
            }
          ],
        }
      }
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  data () {
    return {
      isEdit: false
    }
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
  }
}
</script>

<style lang="less" scoped>
.text-ricepos-box {
  // min-width: 67vw;
  display: flex;
  // justify-content: space-between;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  padding: 74px 360px !important;
  .text-box {
    min-width: 28vw;
    .line-title {
      display: flex;
      align-items: baseline;
      .line {
        width: 4px;
        min-width: 4px;
        height: calc(36px * 0.8);
        background: #1d8cdd;
      }
      .title {
        position: relative;
        font-size: 36px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 800;
        color: #000000;
        margin-left: 12px;
        line-height: 36px;
      }
    }
    .desc {
      font-size: 18px;
    }
    .item {
      .item-title {
        font-weight: 600;
      }
    }
    .item-title {
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .item-desc {
      color: #333333;
      font-size: 18px;
    }
  }
  .image-box {
    width: 36vw;
  }
}

@media screen and(max-width:1000px) {
  .px-text-ricepos-box {
    padding: 30px 70px !important;
    .desc {
      font-size: 14px !important;
    }
    .item {
      font-size: 14px !important;
      .item-title {
        font-size: 14px;
      }
      .item-desc {
        font-size: 14px;
      }
    }
    .text-box {
      width: calc(50% - 5px);
      min-width: calc(50% - 5px);
      .title {
        font-size: 16px !important;
      }
    }
    .image-box {
      width: calc(50% - 5px);
      padding-left: 10px !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .px-text-ricepos-box {
    padding-top: 25px !important;
    padding-right: 12px !important;
    padding-bottom: 25px !important;
    padding-left: 15px !important;
    .text-box {
      width: 50vw;
      .line-title {
        .line {
          width: 2px;
          min-width: 2px;
          height: 10px;
          background: #1d8cdd;
        }
        .title {
          font-size: 15px !important;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 15px;
        }
      }
      .desc {
        font-size: 10px !important;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .item {
        margin-top: 5px !important;
        font-size: 10px !important;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        .item-title {
          margin-top: 0 !important;
          font-size: 10px !important;
        }
        .item-desc {
          line-height: 12px;
          margin-top: 2px;
          font-size: 10px !important;
        }
      }
    }
    .image-box {
      flex: 1;
      padding-left: 0 !important;
    }
  }
}
</style>